import React, { type SyntheticEvent, useEffect } from 'react';

import { useIntl } from 'react-intl-next';

import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import TeamsIcon from '@atlaskit/icon/core/teams';
import { type TriggerProps } from '@atlaskit/popup';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { usePeopleTeamAnalyticsEvents } from '@atlassian/people-teams-ui/analytics';

import messages from './messages';

type TeamButtonTriggerProps = {
	triggerProps: TriggerProps;
	numberOfTeams: number;
	onClick: (_: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

const buttonStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	display: 'flex',
	alignItems: 'center',
	height: '32px',
	width: '32px',
	justifyContent: 'center',
	color: 'color.text.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const buttonStylesWithNumber = xcss({
	width: '56px',
});

export const TeamButtonTrigger = ({
	triggerProps,
	numberOfTeams,
	onClick,
}: TeamButtonTriggerProps) => {
	const { formatMessage } = useIntl();
	const { fireEvent } = usePeopleTeamAnalyticsEvents();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const result = createAnalyticsEvent({})?.context.find(
			(item) => item.attributes && item.attributes.isMultiBoard !== undefined,
		);
		fireEvent('ui.teamButton.viewed', {
			teamsCount: numberOfTeams,
			isMultiBoard: result?.attributes?.isMultiBoard,
		});
	}, [createAnalyticsEvent, fireEvent, numberOfTeams]);

	return (
		<Pressable
			{...triggerProps}
			onClick={onClick}
			xcss={[buttonStyles, numberOfTeams > 1 && buttonStylesWithNumber]}
			testId="team-button-test-id"
		>
			<TeamsIcon color="currentColor" label={formatMessage(messages.iconLabel)} />
			{numberOfTeams > 1 && (
				<Box as="span" paddingInlineStart="space.100">
					{numberOfTeams}
				</Box>
			)}
		</Pressable>
	);
};
